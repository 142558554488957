<template>

  <div class="mx-auto max-w-2xl text-lg text-slate-700 flex flex-col space-y-20 px-10 md:px-1">

    <div>
      <div class="flex-1 justify-center">
        <img src="/img/hero.svg" class=" mx-auto" />
      </div>
      <div class="flex-1 justify-center text-center">
        <div class="text-4xl leading-normal text-black font-bold mb-10 font-lexend">
          How to know if you need a copywriter
        </div>
        <div class="mx-auto max-w-2xl mt-6   flex flex-col text-left space-y-6 leading-loose text-lg text-slate-700">
          <p>It's no secret that writing content for websites, emails, brochures and blogs takes a lot of time and effort.</p>

          <p>It's also no surprise that much of that content doesn't ever get results.</p>

          <p>You see, every piece of written communication used by a business - what the industry calls 'copy' - can be utilised to attract customers and increase sales.</p>

          <p>The right copy will generate leads, amplify revenue and strengthen your brand.</p>

          <p>If you're like most people, you don't have the spare time, energy or expertise to write powerful, engaging and persuasive copy.</p>

          <p>That's where my professional copywriting services can help you get results and give you a competitive edge.</p>

          <p>Get in touch today for a free consultation.</p>
        </div>
      </div>
    </div>

    <div>
      <header class="text-center mx-auto mb-12 block">
        <h2 class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
          What I do</h2>
        <p class="mt-4  text-slate-700">
          My custom copywriting services will save you time and effort while creating a competitive edge
        </p>
      </header>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-5 text-center">
        <div class="col-span-1" v-for="item in data" :key="item.title">
          <div v-html="item.icon" class="inline-block text-gray-900 mb-4 text-center"></div>
          <div class="mt-2 font-display text-xl text-slate-900">{{item.title}}</div>
          <div class="mt-4  text-slate-600">{{item.body}}</div>
        </div>
      </div>
    </div>

    <div>
      <header class="text-center mx-auto mb-12 ">
        <h2 class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">Expertise</h2>
      </header>
      <div class="flex flex-col text-left space-y-6 leading-loose">

        <p>Working for over twenty years in retail, sales, marketing and management, I’ve developed a deep understanding of the reasons people buy products and services and - more importantly - the reasons they don’t.</p>

        <p>My copywriting is based on a reservoir of expert knowledge, so I can provide practical advice that’s relevant to you.</p>

        <p>I’ve written for companies such as Content House, Hays Personnel, Pioneer Mortgage Service, A.B. Paterson College, Threadwise and Tocuhpoint Global.</p>

        <p>Let me show you how my writing experience can help your business thrive.</p>

      </div>
    </div>

    <div>
      <header class="text-center mx-auto mb-12  block">

        <h2 class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">Realestate Copy</h2>

        <p class="mt-4  tracking-tight">Save time managing advertising & Content for your business.</p>
      </header>
      <div class="flex flex-col space-y-5  justify-between ">
        <div class="flex-1">
          <img src="/img/realestate-removebg-preview.png" class="mx-auto w-[500px]" />
        </div>
        <div class="flex-1 text-justify flex flex-col space-y-4 pt-10">

          <p>In 2022 alone I've written over 300 unique property listings - plus accompanying signboards, brochures editorial and newspaper ads- for agents right across Australia.</p>

          <p>Whether it's a budget apartment or luxury estate, I'm skilled at painting the most impressive picture of a property in a way that appeals to its ideal target audience</p>

          <p>My expert copywriting services will:- </p>
          <ul class="leading-tight list-disc ml-10">
            <li>Save you time and effort, leaving you free to work on other areas of your business </li>
            <li>Show a vendor you are serious about using all the marketing tools at your disposal to sell their home </li>
            <li>Use persuasive, targeted words to improve click-through rates and generate more inquiries </li>
          </ul>
          <p>Of course, real estate isn’t just about property listings. I can help bolster the professional impression of your agency by writing website copy, agent profiles, marketing collateral, email campaigns and more!</p>

          <p>To find out exactly how my copywriting services can help you, talk to me today.</p>

        </div>
      </div>
    </div>

    <div class=" mx-auto pb-20">
      <header class="text-center mx-auto mb-12">
        <h2 class="font-display text-2xl tracking-tight text-slate-900">Contact</h2>
      </header>

      <div class="flex flex-col space-y-3">
        <div class="flex flex-1 items-center space-x-3">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-200 flex-shrink-0 ">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
          </svg>

          <a class="text-center leading-normal  text-primary-600" href="tel: 0402 475 121">
            0402 475 121
          </a>
        </div>
        <div class="flex flex-1 items-center space-x-3">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-200 flex-shrink-0 ">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
          </svg>
          <a class="text-center leading-normal  text-primary-600" href="mailto: copy@klairejohnston.com">
            copy@klairejohnston.com
          </a>
        </div>
      </div>
      <img src="/img/logo.png" class=" mx-auto h-20 mt-20" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      data: [
        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path></svg>',
          title: "Email Campaigns",
          body: "Conversion-focused emails to help you cut through the ‘noise’ of a crowded inbox.",
        },

        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-chat-square-dots" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path></svg>',
          title: "Website Copy",
          body: "Content that prompts action, builds credibility and is consistent with your brand.",
        },
        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-badge-ad" viewBox="0 0 16 16"><path d="M3.7 11l.47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852l.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z"></path><path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"></path></svg>',
          title: "Real Estate Listings",
          body: "Painting an ideal picture of the property, making it stand out against the competition.",
        },
        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" /></svg>',
          title: "Lead Magnets",
          body: "Long-form online resources, such as e-books, checklists and case studies, to attract potential clients.",
        },
      ],
    };
  },
};
</script>


<style scoped>
</style>