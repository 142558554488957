<template>
  <div class="py-5">
    <div class="">
      <div class="max-w-5xl mx-auto ">
        <nav class="flex  justify-between items-center relative p-5 mt-5">
          <router-link to="/" class="flex-1">
            <img src="/img/logo.png" class=" mx-auto h-20" />
          </router-link>
        </nav>
      </div>
      <div class=" bg-white rounded-md">
        <router-view />
      </div>

    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      show_menu: false,
    };
  },
  methods: {},
};
</script>

<style>
</style>